import React from "react"
import moment from "moment"

import profile from "../stores/profile"

export default class Header extends React.Component {
  render() {
    return (
      <header
        className="main-header"
        style={{ backgroundImage: "url(/images/cover.jpg)" }}
      >
        <div className="container">
          <div className="row personal-profile">
            <div className="col-md-4 personal-profile__avatar">
              <img className="" src="/images/profile.jpg" alt="avatar" />
            </div>
            <div className="col-md-8">
              <p className="personal-profile__name">Thanapat Pirmphol</p>
              <p className="personal-profile__work">
                Software Engineer, Photographer
              </p>
              <div className="personal-profile__contacts">
                <dl className="contact-list contact-list__opacity-titles">
                  {/* <dt>Age:</dt>
                            <dd>{moment().year() - profile.birthdate.year()}</dd> */}
                  <dt>Phone:</dt>
                  <dd>
                    <a href={`tel: ${profile.phone}`}>{profile.phone}</a>
                  </dd>
                  <dt>Email:</dt>
                  <dd>
                    <a href={`mailto: ${profile.email}`}>{profile.email}</a>
                  </dd>
                  <dt>Address:</dt>
                  <dd>{profile.address}</dd>
                </dl>
              </div>
              <p className="personal-profile__social">
                <a href={profile.facebook} target="_blank" alt="Facebook">
                  <i className="fa fa-facebook-square"></i>
                </a>
                <a href={profile.linkedin} target="_blank" alt="LinkedIn">
                  <i className="fa fa-linkedin-square"></i>
                </a>
                <a href={profile.github} target="_blank" alt="Github">
                  <i className="fa fa-github"></i>
                </a>
              </p>
            </div>
          </div>
        </div>
      </header>
    )
  }
}
