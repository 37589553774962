export default {
  education: [
    {
      from: 2002,
      to: 2004,
      school: "Chulalongkorn University",
      degree: "Master of Science (M.S.)",
      major: "MIS",
      gpa: 3.59,
    },
    {
      from: 1997,
      to: 2001,
      school: "Burapha University",
      degree: "Bachelor of Science (B.S.)",
      major: "Computer Science",
      gpa: 2.79,
    },
  ],
  employment: [
    {
      from: "2020",
      to: "Present",
      employer: "Civic Ledger",
      title: "Senior Software Engineer/Blockchain Developer",
      tags: [
        "NodeJS",
        "AdonisJS",
        "ReactJS/Typescript",
        "PostgreSQL",
        "TailwindCSS",
        "Github Actions",
        "Hedera Hashgraph",
      ],
    },
    {
      from: "2020",
      to: "2022",
      employer: "4-Ti",
      title: "Senior Software Engineer/Development Team Lead",
      tags: [
        "NodeJS",
        "KoaJS",
        "ReactJS/Typescript",
        "MongoDB",
        "TailwindCSS",
        "Github Actions",
        "Azure Kubernetes",
        "Auth0",
        "Twilio",
        "Datatrans",
      ],
    },
    {
      from: "2020",
      to: "2020",
      employer: "Aetna Health Insurance",
      title: "Senior Manager - Application Development and Support",
      tags: ["MS SQL Server", "Oracle", "ASP.NET"],
    },
    {
      from: "2019",
      to: "2020",
      employer: "Adastra",
      title: "IT Business Consultant",
      tags: [
        "PowerBI",
        "TeraData",
        "Microstrategy",
        "ReactJS",
        "Fabric UI",
        "HTML5/CSS3",
        "MS SQL Server",
      ],
    },
    {
      from: "2011",
      to: "2018",
      employer: "Unilode Aviation Solutions",
      title: "Software Engineer",
      tags: [
        "ASP.NET/C#",
        "PHP/Laravel",
        "Python",
        "SQL Server",
        "MongoDB",
        "Linux",
        "MS Azure",
        "AngularJS",
        "ReactJS",
        "jQuery",
        "HTML5/CSS3",
        "Redis",
        "Git",
        "CI",
      ],
    },
    {
      from: "2010",
      to: "2011",
      employer: "ExxonMobil",
      title: "Application Analyst",
      tags: ["Visual Basic", "Oracle", "SQL Server", "Powerbuilder"],
    },
    {
      from: "2005",
      to: "2010",
      employer: "Softscape Asia",
      title: "Development Lead/Project Manager",
      tags: ["HTML", "Javascript", "SQL Server", "Cognos"],
    },
    {
      from: "2005",
      to: "2002",
      employer: "Progress Information Ltd.",
      title: "Development Lead",
      tags: ["PHP", "MySQL", "Linux", "HTML", "Javascript"],
    },
  ],
  skills: [
    { name: "NodeJS", value: 80 },
    { name: "Javascript", value: 80 },
    { name: "Typescript", value: 80 },
    { name: "PHP/Laravel", value: 80 },
    { name: "HTML5/CSS3", value: 80 },
    { name: "ReactJS", value: 75 },
    { name: "SQL", value: 70 },
    { name: "PowerBI", value: 60 },
    { name: "MicroStrategy", value: 50 },
    { name: "MongoDB", value: 70 },
    { name: "Linux", value: 70 },
    { name: "Angular", value: 70 },
    { name: "VueJS", value: 60 },
    { name: "Python", value: 50 },
    { name: "ASP.NET/C#", value: 30 },
    { name: "Blockchain", value: 50 },
    { name: "Machine Learning/Tensorflow", value: 30 },
    { name: "Adobe Lightroom/Photoshop", value: 60 },
    { name: "Watching Movies", value: 100 },
    { name: "Cooking", value: 100 },
  ],
}
