import React from 'react';

import portfolio from '../stores/portfolio';

export default class Portfolio extends React.Component {
    render () {
        return (
            <section id="portfolio" className="container section">
                <div className="row">
                    <div className="col-md-12">
                        <h2 id="portfolio_header" className="section__title">Portfolio</h2>
                    </div>
                </div>
                <div className="portfolio-cards">
                    {portfolio.projects.map(project => (
                        <div key={project.name} onClick={(e) => {window.open(project.url)}} className="row project-card">
                            <div className="col-md-6 col-lg-5 project-card__img">
                                <img className="" src={project.screenshot} alt="project-img" />
                            </div>
                            <div className="col-md-6 col-lg-7 project-card__info">
                                <h3 className="project-card__title">{project.name}</h3>
                                <p className="project-card__description">{project.description}</p>
                                <a href={project.url} className="project-card__link" target="_blank">{project.url}</a>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        );
    }


}