export default {
  projects: [
    {
      name: "Water Ledger",
      url: "https://www.waterledger.com",
      description: "as Senior Software Engineer",
      screenshot: "/images/screen9.png",
    },
    {
      name: "Rikai",
      url: "https://www.rikai.ch",
      description: "as Developement Team Lead",
      screenshot: "/images/screen8.png",
    },
    {
      name: "Panohive",
      url: "https://www.panohive.com",
      description: "Virtual Tour Blog",
      screenshot: "/images/screen1.png",
    },
    {
      name: "BeHappyNerd.com",
      url: "https://blog.oadtz.com",
      description: "My Personal Blog",
      screenshot: "/images/screen4.png",
    },
    {
      name: "Gearbuk.com",
      url: "https://www.gearbuk.com",
      description: "Camera Gear Rental",
      screenshot: "/images/screen7.png",
    },
    {
      name: "TagToast",
      url: "https://tagtoast.oadtz.com",
      description:
        "Online Keywords and Hashtags Generator for Instagram and SEO",
      screenshot: "/images/screen2.png",
    },
    {
      name: "Vdo2Gif",
      url: "https://vdo2gif.oadtz.com",
      description: "Simple gif animation converter",
      screenshot: "/images/screen3.png",
    },
    {
      name: "Contributor at Pixabay",
      url: "https://pixabay.com/en/users/oadtz-3657813/",
      description: "Photos for free download at Pixabay",
      screenshot: "/images/screen5.png",
    },
    {
      name: "Contributor at VideoHive",
      url: "https://videohive.net/user/oadtz/portfolio",
      description: "Clips and footages at Envato Market",
      screenshot: "/images/screen6.png",
    },
  ],
  certifications: [
    {
      name: "MS Azure Musketeer",
      issuer: "Microsoft",
      url:
        "https://drive.google.com/file/d/1FJ8n70l3Ci6AykRo8GRUjD8helvAtS9o/view",
      screenshot: "/images/cert7.png",
    },
    {
      name: "Power BI",
      issuer: "Microsoft x Edx",
      url:
        "https://courses.edx.org/certificates/98a60c39783140dc8713e0691a994c0c",
      screenshot: "/images/cert6.png",
    },
    {
      name: "Machine Learning",
      issuer: "Coursera x Stanford University",
      url:
        "https://www.coursera.org/account/accomplishments/certificate/VKD5Z7RX3PDH",
      screenshot: "/images/cert1.png",
    },
    {
      name: "MongoDB for Developers",
      issuer: "MongoDB University",
      url:
        "https://s3.amazonaws.com/edu-cert.10gen.com/downloads/8a9a7d7cd1fd4189b04befb4051dffe0/Certificate.pdf",
      screenshot: "/images/cert2.png",
    },
    {
      name: "MongoDB for DBAs",
      issuer: "MongoDB University",
      url:
        "https://s3.amazonaws.com/edu-cert.10gen.com/downloads/c9b886d861a04a1fbab38c4da5e9a2b6/Certificate.pdf",
      screenshot: "/images/cert3.png",
    },
    {
      name: "Google Street View Trusted Photographer",
      issuer: "Google",
      url: "https://www.google.com/streetview/hire/",
      screenshot: "/images/cert4.png",
    },
    {
      name: "TOEIC - Score 905",
      issuer: "TOEIC Services Thailand",
      url:
        "https://drive.google.com/file/d/0B8GcvCB4kKJuc1FqYWFSc3dCeVk/view?usp=sharing",
      screenshot: "/images/cert5.png",
    },
  ],
  blogs: [
    {
      title: "พาทัวร์​ TCDC Commons - W District",
      url: "https://www.panohive.com/tour/tcdc-commons",
      description:
        "TCDC Commons - W District ห้องสมุดและที่นั่งทำงานกลางย่านสุดชิคของสุขุมวิท ใกล้ BTS พระโขนง",
      thumbnail:
        "https://api.panohive.com/storage/places/5b8ba69c532bf761372afbf4/5b8ba69c532bf761372afbf3_md.jpg",
      site: "www.panohive.com",
    },
    {
      title: "วิดีโอรีวิว: เปลี่ยนรูปถ่ายให้มีชีวิตด้วย Plotagraph+",
      url:
        "https://blog.oadtz.com/photography/2017/08/11/how-to-plotagraph-plus",
      description:
        "เปลี่ยนรูปถ่ายธรรมดาให้มีชีวิต และสะดุดตาขึ้นด้วยแอพ Plotagraph+ Photo Animator บน iOS",
      thumbnail:
        "https://blog.oadtz.com/storage/image/598cd96c62304913ab7d6f01_thumbnail.jpg",
      site: "blog.oadtz.com",
    },
  ],
}
