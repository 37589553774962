import React from 'react';
import MainMenu from './MainMenu';
import Header from './Header';
import About from './About';
import Experience from './Experience';
import Portfolio from './Portfolio';
import Certifications from './Certifications';
import Blog from './Blog';
import Contact from './Contact';

class App extends React.Component {
    render () {
        return (
            <>
                <MainMenu />
                <Header />
                <About />
                <hr/>
                <Experience />
                <Portfolio />
                <Certifications />
                <Blog />
                <Contact />
            </>
        );
    }

    componentDidMount () {

        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = '/js/vendor.js';
            fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'vendor-js'));
    }
}

export default App;