import moment from "moment"

export default {
  birthdate: moment([1979, 9, 30]),
  phone: "+6662 529 8995, +6665 429 6645",
  email: "t.pirmphol@gmail.com",
  address: "Bangkok, Thailand",
  about: `Experienced Software Engineer with a demonstrated history of working in blockchain technology, insurance, IT consultancy, and aviation industry. Familiar with working/leading development team in multi-time zone and multi-cultural companies. Skilled in Full stack javascript application development, blockchain, SQL and NoSQL Database, DevOps and cloud platforms. Strong engineering professional with a Master of Science (M.S.) focused in MIS from Chulalongkorn University. `,
  website: "https://www.oadtz.com",
  facebook: "https://www.facebook.com/thanapat.pirmphol",
  linkedin: "https://www.linkedin.com/in/thanapatpirmphol/",
  github: "https://www.github.com/oadtz",
  line: "oadtz",
}
