import React from "react"

import portfolio from "../stores/portfolio"

export default class Blog extends React.Component {
  render() {
    return (
      <section id="blog" className="container section">
        <div className="row">
          <div className="col-md-12">
            <h2 id="blog_header" className="section__title">
              Sample Blog Posts
            </h2>
          </div>
        </div>

        <div className="row post-cards">
          {portfolio.blogs.map(blog => (
            <div key={blog.title} className="col-md-4">
              <a href={blog.url} target="_blank">
                <div className="post-cards__card">
                  <div className="post-cards__img">
                    <img src={blog.thumbnail} alt="blog_img" />
                  </div>
                  <div className="post-cards__info">
                    <p className="post-cards__date">{blog.site}</p>
                    <h3 className="post-cards_title">{blog.title}</h3>
                    <p className="post-cards_description">{blog.description}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
          <div className="col-md-4">
            <div className="post-cards__card">
              <div className="post-cards__img">
                <img src="/images/img_blog_3.png" alt="blog_img" />
              </div>
              <div className="post-cards__info">
                <h3 className="post-cards_title">Read more on</h3>
                <p className="post-cards_description">
                  <ul>
                    <li>
                      <a href="https://www.panohive.com" target="_blank">
                        https://www.panohive.com
                      </a>
                    </li>
                    <li>
                      <a href="https://blog.oadtz.com" target="_blank">
                        https://blog.oadtz.com
                      </a>
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
