import React from 'react';

import portfolio from '../stores/portfolio';

export default class Certifications extends React.Component {
    render () {
        return (

            <div id="certifications" className="section">
                <div className="background slider-carousel" style={{backgroundImage: 'url(/images/img_bg_main.jpg)'}}>
                <div className="container">
                    <div id="carouselTestimonials" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators">
                        {portfolio.certifications.map((cert, i) => 
                        <li key={cert.name} data-target="#carouselTestimonials" data-slide-to={i} className={i === 0 ? 'active' : ''}></li>)}
                    </ol>
                    <div className="carousel-inner" role="listbox">
                        {portfolio.certifications.map((cert, i) => (
                            <div key={cert.name} className={`carousel-item ${i === 0 ? 'active' : ''}`}>
                                <div className="row">
                                    <div className="col-md-10 col-sm-10 col-10 mr-auto ml-auto">
                                        <p className="slider-carousel__title">
                                            <a href={cert.url} target="_blank" style={{color: 'white', textDecoration: 'none'}}>{cert.name} <i className="fa fa-external-link"></i></a>
                                        </p>
                                        <p className="slider-carousel__caption">Issuer: {cert.issuer}</p>
                                        <hr/>
                                        <p className="slider-carousel__description text-center">
                                            <a href={cert.url} target="_blank"><img src={cert.screenshot} /></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <a className="carousel-control-prev" href="#certifications" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#certifications" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                    <div className="slider-carousel__circle">
                        <p><i className="fa fa-graduation-cap" aria-hidden="true"></i></p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}