import React from 'react';

import experience from '../stores/experience';

export default class Experience extends React.Component {
    render () {
        return (

            <section id="experience" className="container section">
                <div className="row">
                <div className="col-md-10">
                    <h2 id="resume_header" className="section__title">Experience</h2>
                </div>
                </div>
                <div className="row">
                <div className="col-md-8 section__resume resume-list">
                    <h3 className="resume-list_title">education</h3>
                    {experience.education.map(education => (

                        <div className="resume-list__block" key={education.school}>
                            <p className="resume-list__block-title">{education.school}</p>
                            <p className="resume-list__block-date">{education.from} - {education.to}</p>
                            <p>
                                {education.degree}, {education.major}, {education.gpa}
                            </p>
                        </div>
                    ))}
                </div>
                </div>
                <div className="row">
                <div className="col-md-8 section__resume resume-list">
                    <h3 className="resume-list_title">employment</h3>
                    {experience.employment.map(employment => (
                        <div className="resume-list__block" key={employment.employer}>
                            <p className="resume-list__block-title">{employment.employer}</p>
                            <p className="resume-list__block-date">{employment.from} - {employment.to}</p>
                            <p>
                                {employment.title}
                            </p>
                            <ul className="tags">
                                {employment.tags.map(tag => (<li key={tag}>{tag}</li>))}
                                
                            </ul>
                        </div>
                    ))}
                </div>
                </div>
                <div className="row section__resume progress-list js-progress-list">
                    <div className="col-md-12">
                        <h3 className="progress-list__title">additional skills</h3>
                    </div>
                    {experience.skills.map(skill => (
                        <div className="col-md-5 mr-auto" key={skill.name}>
                            <div className="progress-list__skill">
                                <p>
                                    <span className="progress-list__skill-title">{skill.name}</span>
                                </p>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={skill.value} aria-valuemin="0" aria-valuemax="100" >
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        );
    }
}