import React from "react"

import profile from "../stores/profile"

export default class About extends React.Component {
  render() {
    return (
      <section id="about" className="container section">
        <div className="row">
          <div className="col-md-10">
            <h2 id="hello_header" className="section__title">
              Who am I?
            </h2>
            <p
              className="section__description"
              style={{ whiteSpace: "pre-line" }}
            >
              {profile.about}
            </p>
          </div>
        </div>
      </section>
    )
  }
}
